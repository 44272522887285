<template>
  <div class="container">
    <h2>Relatorio de Produtos Aerosol</h2>
    <h5>
      Total de Produtos: <strong>{{ report.length }}</strong>
    </h5>
    <b-table
      striped
      hover
      :items="report"
      :fields="fields"
      :filter="filter"
      :per-page="rows"
      thead-class="text-blue"
      class="table"
    >
      <template #cell(sku)="data">
        <b-link :href="`/product/${data.item.id_backoffice}`">{{
          data.item.sku[0]
        }}</b-link>
        <p v-if="data.item.sku.length > 1">
          (
          <a v-for="prod in data.item.sku" :key="prod">
            <a v-if="prod != data.item.sku[0]">
              {{ prod }}
            </a>
          </a>
          )
        </p>
      </template>
      <template #cell(ean)="data">
        <p v-for="prod in data.item.ean" :key="prod">
          {{ prod }}
        </p>
      </template>
      <template #cell(aerosol)="data">
        <span v-if="data.item.aerosol == null">
          <p><b>É um aerosol?</b></p>
          <button
            class="btn btn-success"
            @click="updateAerosol(data.item, true)"
          >
            Sim
          </button>
          <button
            class="btn btn-danger"
            @click="updateAerosol(data.item, false)"
          >
            Não
          </button>
        </span>
        <span v-else-if="data.item.aerosol == true">
          <p><b>É um aerosol</b></p></span
        >
        <span v-else-if="data.item.aerosol == false">
          <p><b>Não é um aerosol</b></p></span
        >
      </template>
    </b-table>

    <h5>Load +100</h5>
    <b-modal
      ref="modal_marca"
      id="modal-marca"
      :title="'Adicionar Marca'"
      hide-footer
    >
      <form v-on:submit.prevent="addBrandToProduct()">
        <div class="form-group">
          <p>
            SKU: <b>{{ productSku }}</b>
          </p>
          <p>
            <b>{{ productName }}</b>
          </p>
          <label for="addressInput">Marca:</label>
          <v-select :options="allBrands" v-model="selectedBrand" label="name">
            <template slot="option" slot-scope="option">
              {{ option.name }}</template
            >
          </v-select>
        </div>
        <div class="selectBtn">
          <button type="submit" class="btn btn-primary">Adicionar Marca</button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: function () {
    return {
      report: [],
      filter: null,
      productName: "",
      productIdBackoffice: "",
      productSku: "",
      rows: 100,
      fields: [
        {
          key: "sku",
          label: "SKU/CNP",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "ean",
          label: "EAN",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "name",
          label: "Nome",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "aerosol",
          label: "Validar Aerosol",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
      ],
      allBrands: [],
      selectedBrand: "",
    };
  },
  methods: {
    async moreProducts() {
      this.rows += 100;
    },
    async aerosolReport() {
      try {
        await this.$store.dispatch("aerosolProductsReport");
        console.log(this.getAerosolReport);
        this.report = this.getAerosolReport;
      } catch (err) {
        console.log(err);
        alert(err);
      }
    },
    async addBrandToProduct() {
      try {
        await this.$store.dispatch("updateProductBrand", {
          id: this.productIdBackoffice,
          brand: this.selectedBrand.id,
        });
        this.$swal({
          toast: true,
          icon: "success",
          title: "Marca adicionada ao produto com sucesso",
          showConfirmButton: false,
          timer: 3000,
          position: "top-end",
        });
        this.$refs["modal_marca"].hide();
        await this.brandReport();
      } catch (err) {
        console.log(err);
        alert(err);
      }
    },
    async updateAerosol(values, aerosol) {
      console.log(values);
      try {
        await this.$store.dispatch("updateAerosolProduct", {
          id: values.id_backoffice,
          aerosol: aerosol,
        });
        this.$swal({
          toast: true,
          icon: "success",
          title: "Produto atualizado com sucesso",
          showConfirmButton: false,
          timer: 3000,
          position: "top-end",
        });
        values.aerosol = aerosol;
      } catch (err) {
        console.log(err);
        alert(err);
      }
    },
    async productData(name, sku, id) {
      this.productName = name;
      this.productIdBackoffice = id;
      this.productSku = sku;
    },
    routeToProduct(productId) {
      this.$router.push(`product/${productId}`);
    },
    async scroll() {
      window.onscroll = async () => {
        let bottomOfWindow =
          Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop
          ) +
            window.innerHeight ===
          document.documentElement.offsetHeight;

        if (bottomOfWindow) {
          await this.moreProducts();
        }
      };
    },
    // splitDate(date) {
    //   let split = date.split('T');
    //   return split[0].substring(0, split[0].length - 3);
    // },
  },
  async created() {
    this.aerosolReport();
  },
  mounted() {
    this.scroll();
  },
  computed: {
    ...mapGetters(["getAerosolReport"]),
  },
};
</script>

<style scoped>
.table {
  text-align: center;
}
.buttons {
  text-align: center;
  margin: 15px 0px 15px 0px;
}
h2 {
  text-align: center;
  margin-top: 5px;
}
h5 {
  text-align: center;
}
/* .btn {
  margin: 0px 5px 0px 5px;
} */
</style>